import React, { useRef, useState } from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import MuiDrawer from '@mui/material/Drawer';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MobileStepper from '@mui/material/MobileStepper';
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Progress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from '@mui/material/OutlinedInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { useCookies } from 'react-cookie';
import CookieConsent from "react-cookie-consent";

import PersonIcon from '@mui/icons-material/PersonOutline';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  RangeDatePicker,
  SingleDatePicker
} from "react-google-flight-datepicker";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import NotificationIcon from '@mui/icons-material/NotificationsNone';

import RentIcon from '@mui/icons-material/Apartment';
import BusIcon from '@mui/icons-material/DirectionsBus';
import ExploreIcon from '@mui/icons-material/Explore';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import MenuIcon from '@mui/icons-material/Menu';
import TaskIcon from '@mui/icons-material/Task';

import OnewayIcon from '@mui/icons-material/ArrowRightAlt';
import RoundtripIcon from '@mui/icons-material/SyncAlt';
import MulticityIcon from '@mui/icons-material/MultipleStop';
import AdultIcon from '@mui/icons-material/PersonOutline';
import ChildrenIcon from '@mui/icons-material/Face';
import InfantIcon from '@mui/icons-material/ChildCare';

import FromIcon from '@mui/icons-material/TripOrigin';
import ToIcon from '@mui/icons-material/GpsFixed';

import DotsIcon from '@mui/icons-material/MoreVert';

import EconomyIcon from '@mui/icons-material/Toll';
import BusinessIcon from '@mui/icons-material/WorkOutline';
import FirstIcon from '@mui/icons-material/StarOutline';

import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { createFilterOptions } from "@mui/material/Autocomplete";

import Book from './Book';

import "./css/style.css";
import "./css/date.css";

const humanizeDuration = require("humanize-duration");
const shortEnglishHumanizer = humanizeDuration.humanizer({
  conjunction: " ",
  serialComma: false,
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "year",
      mo: () => "month",
      w: () => "week",
      d: () => "day",
      h: () => "hr",
      m: () => "min",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

const SelectInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
  },
  '& .MuiInputBase-input': {
    display: 'flex',
    border: '0px',
    color: '#555',
    fontSize: 13.5,
    fontFamily: [
      'Product Sans',
      'Roboto',
    ].join(','),
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerWidth = 240;//window.screen.width / 100 * 85;

const menuTitles = ['Explore', 'Bus', 'Flights', 'Hotel', 'Rentals', 'To do' ];
const menuIcons = [<ExploreIcon/>, <BusIcon/>, <FlightIcon/>, <HotelIcon/>, <RentIcon/>, <TaskIcon/>];

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`,
    [theme.breakpoints.up('sm')]: {
      width: `0px`,
    },
  });


  const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction="up" ref={ref} {...props} />;
  });

  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  const images = [
	  {
		      label: 'San Francisco',
		      imgPath:
		        'https://static.studyusa.com/article/cdn_WVXmlPuT-_PkSSmR1FZEQ-MrNR4oOvX5.jpg',
		    },
	  {
		      label: 'Dubai',
		      imgPath:
		        'https://www.campings-outdoor.com/wp-content/uploads/2020/10/1200px-BW_2012-08-26_Dorothea_Karalus_GER_0541-min.jpg',
		    },
	  {
		      label: 'Diani',
		      imgPath:
		        'https://www.elewanacollection.com/images/acd/AfroChic---Swahili-bed-on-beach.jpg',
		    },
	  {
		      label: 'Santorini',
		      imgPath:
		      'https://thumbor.bigedition.com/white-washed-houses-in-santorini/siyAWn4oCFZice4SyOVDpi7h8gM=/800x600/filters:format(webp):quality(80)/granite-web-prod/c7/db/c7db8df857e14634b45b9d4a89675f08.jpeg',
		     },
  ];

  const theme = createTheme({
    palette: {
      primary: {
        light: '#f9eef0',
        main: '#f93b57',
        dark: '#844800',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ccc',
        main: '#666',
        dark: '#222',
        contrastText: '#000',
      },
      white: {
        light: '#ccc',
        main: '#fff',
        dark: '#222',
        contrastText: '#000',
      },
    },
  });

var json;
var info;
var loading;
var message;

export default function Search() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isSingle, setIsSingle] = useState(false);
  const [from, setFrom] = React.useState("");
  const [to, setTo] = React.useState("");
  const [cat, setCat] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [type, setType] = React.useState("");
  const [adults, setAdults] = React.useState(1);
  const [children, setChildren] = React.useState(0);
  const [infants, setInfants] = React.useState(0);

  const [cookies, setCookie] = useCookies(['name']);

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const pop = Boolean(anchorEl);
  const pid = pop ? 'simple-popover' : undefined;

  var onDate;
  var returnDate;

  const initDefault = () => {
    if(!cat) setCat("economy");
    if(!pass) setPass("1");
    if(!type) setType("return");
  }
  initDefault();

  const handleShowInfo = () => {
      info = true;
  };

  const handleCloseInfo = () => {
      info = false;
  };


  const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
      setActiveStep(step);
  };

  const handleFrom = (event, value) => {
    setFrom(value);
    console.log(value);
    if(to && onDate && (type=="oneway" || returnDate)) {
      search(type, 'economy', value, to, adults, children, infants, onDate, returnDate);
    }
  }

  const handleTo = (event, value) => {
    setTo(value);
    console.log(value);
    if(from && onDate && (type=="oneway" || returnDate)) {
      search(type, 'economy', from, value, adults, children, infants, onDate, returnDate);
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePop = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePass = () => {
    setAnchorEl(null);
    setPass(adults + children + infants);
  };

  const onDateChange = (start, end) => {
    onDate = start == null ? ""
                    : start.getFullYear() + "-" + ((String(start.getMonth()+1).length==1 ? "0" : "") + (start.getMonth()+1)) + "-" + ((String(start.getDate()).length==1 ? "0" : "") + start.getDate());
    returnDate = end == null || type != "return" ? ""
                    : end.getFullYear() + "-" + ((String(end.getMonth()+1).length==1 ? "0" : "") + (end.getMonth()+1)) + "-" + ((String(end.getDate()).length==1 ? "0" : "") + end.getDate());
    console.log("On: " + onDate);
    console.log("Return: " + returnDate);
    if(from && to && onDate && (type=="oneway" || returnDate)) {
      search(type, 'economy', from, to, adults, children, infants, onDate, returnDate);
    }
  }

  const handleCat = (value) => { setCat(value); };

  const handleType = (value) => {
    setType(value);
    switch (value) {
      case 'oneway':
        setIsSingle(true);
        break;
      case 'roundtrip':
        setIsSingle(false);
        setType("return");
        break;
      case 'multicity':
        setIsSingle(true);
        break;
      default:
        break;
    }
  };


  function onChange(newName) {
    setCookie('name', newName, { path: '/' });
  }
	
  return (
    <React.Fragment>
    <ThemeProvider theme={theme}>
      <div className="App">
       <AppBar className="AppBar" elevation={0} position="fixed" open={open}>
         <Toolbar className="Toolbar">
           <IconButton
             aria-label="open drawer"
             onClick={handleDrawerOpen}
            edge="start"
             sx={{
               marginRight: '36px',
               ...(open && { display: 'none' }),
             }}
           >
             <MenuIcon />
           </IconButton>
           <Typography
             color="#111"
             component="div"
              sx={{
                ...(open ? { display: 'none' } : {alignSelf: 'flex-end'}),
              }}>
             <h3><strong>Safiri</strong>Express</h3>
           </Typography>
           <div className="Menu">
              <Badge badgeContent={0} color="primary">
                <NotificationIcon color="action" />
              </Badge>
	   </div>
	   <div className="MenuMargin">
             <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="secondary"
                 sx={{
                   float: 'end',
                 }}
                >
	     <PersonIcon color="action" />
            </IconButton>
          </div>
         </Toolbar>
       </AppBar>
       <Drawer variant="permanent" anchor="left" open={open} className="Drawer">
         <DrawerHeader>
           <IconButton onClick={handleDrawerClose}>
             {theme.direction === 'rtl' ? <ChevronRightIcon /> : <CloseIcon />}
           </IconButton>
         </DrawerHeader>
         <List>
           {menuTitles.map((text, index) => (
             <ListItem button key={text} sx={{ marginTop: 1.75 }}>
               <ListItemIcon>
                 {menuIcons[index]}
               </ListItemIcon>
               <ListItemText primary={text}  sx={{ fontSize: 10, fontFamily: 'Product Sans' }} />
             </ListItem>
           ))}
         </List>
       </Drawer>

       <div className="Header">Flights</div>

       <div className="Body">
         <div className='Hello Section'>
	 	Hello {cookies.name ? cookies.name : 'there'}, 
         </div>
         <div className='Welcome  Section'>
             Book cheap flights easily on the go!
           </div>

         <div className="Card">
            <Paper elevation={0} className="Paper">
              <div className='Search'>
                <div className='Section Margin'>
                  <FormControl variant="standard" className="FormControl">
                    <Select
                     className='Select'
                      defaultValue={'roundtrip'}
                      labelId="type"
                      id="type"
                      onChange={(event) => handleType(event.target.value)}
                      input={<SelectInput />}
                    >
                      <MenuItem className="MenuItem" value={'oneway'}><OnewayIcon className="Icon"/>One-way</MenuItem>
                      <MenuItem className="MenuItem" value={'roundtrip'}><RoundtripIcon className="Icon"/>Round-trip</MenuItem>
                      <MenuItem className="MenuItem" value={'multicity'}><MulticityIcon className="Icon"/>Multi-city</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="standard" className="FormControl">
                    <Box aria-describedby={pid} className="PopBox" onClick={handlePop('bottom-start')}>
                      <AdultIcon className="IconSmall"/>
                      <p className="PopText">{pass}</p>{pop ? <ArrowDropUpIcon className="PopIcon" /> : <ArrowDropDownIcon className="PopIcon" />}
                    </Box>
                    <Popover
                      id={pid}
                      open={pop}
                      anchorEl={anchorEl}
                      onClose={handlePass}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      >
                        <Paper className="PopOver">
                          <MenuItem className="MenuItem">Adults
                              <div className="Level">
                                <Button variant="text" onClick={() => setAdults(adults > 1 ? adults - 1 : 1)}><RemoveIcon className="Levelcon"/></Button>
                                <div className="LevelItem">
                                  {adults}
                                </div>
                                <Button variant="text" onClick={() => setAdults(adults + 1)}><AddIcon className="Levelcon"/></Button>
                              </div>
                          </MenuItem>
                          <MenuItem className="MenuItem">Children
                              <div className="Level">
                                <Button variant="text" onClick={() => setChildren(children > 0 ? children - 1 : 0)}><RemoveIcon className="Levelcon"/></Button>
                                <div className="LevelItem">
                                  {children}
                                </div>
                                <Button variant="text" onClick={() => setChildren(children + 1)}><AddIcon className="Levelcon"/></Button>
                              </div>
                          </MenuItem>
                          <MenuItem className="MenuItem">Infants
                              <div className="Level">
                                <Button variant="text" onClick={() => setInfants(infants > 0 ? infants - 1 : 0)}><RemoveIcon className="Levelcon"/></Button>
                                <div className="LevelItem">
                                  {infants}
                                </div>
                                <Button variant="text" onClick={() => setInfants(infants + 1)}><AddIcon className="Levelcon"/></Button>
                              </div>
                          </MenuItem>
                          <Box className="PopButtons">
                            <Button className="PopButton" onClick={() => handlePass()}>Cancel</Button>
                            <Button className="PopButton" onClick={() => handlePass()}>Done</Button>
                          </Box>
                        </Paper>
                    </Popover>
                  </FormControl>
                  <FormControl variant="standard"className="FormControl">
                        <Select
                         className='Select'
                          defaultValue={'economy'}
                          labelId="class"
                          id="class"
                          onChange={(event) => handleCat(event.target.value)}
                          input={<SelectInput />}
                        >
                          <MenuItem className="MenuItem" value={'economy'}><EconomyIcon className="Icon"/>Economy</MenuItem>
                          <MenuItem className="MenuItem" value={'business'}><BusinessIcon className="Icon"/>Business</MenuItem>
                          <MenuItem className="MenuItem" value={'first'}><FirstIcon className="Icon"/>First</MenuItem>
                        </Select>
                      </FormControl>
                  </div>
                </div>
                <div className='Search'>
                  <div className='Section'>
                    <Autocomplete
                      className="Autocomplete"
                      disablePortal
                      id="from"
                      options={airports}
                      filterOptions={filterOptions}
                      getOptionLabel={optionLabel}
                      renderOption={(props, option) => (
                        <Box component="li" className="Box" {...props}>
                          {option.label} ({option.value})
                        </Box>
                      )}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onInputChange={(event, any) => handleFrom(event, any)}
                      renderInput={(params) =>
                        <TextField
                          variant="outlined"
                          className="TextField" {...params}
                          label="Where from"
                          value={from}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <FromIcon className="Adorn"/>
                              </InputAdornment>
                            )
                          }}
                          sx={{
                            "& .MuiFormLabel-root": { transform: from.length > 0 ? "" : "translate(40px, 17px) scale(1)" },
                            "& .MuiFormLabel-root.Mui-focused": {color: "#f93b57", transform: "translate(14px, -9px) scale(0.75)"},
                          }}
                            />}
                          sx={{ width: "100%" }}
                    />
                  </div>
                  <div className='Section'>
                    <Autocomplete
                      className="Autocomplete"
                      disablePortal
                      id="to"
                      options={airports}
                      filterOptions={filterOptions}
                      getOptionLabel={optionLabel}
                      renderOption={(props, option) => (
                        <Box component="li" className="Box" {...props}>
                          {option.label} ({option.value})
                        </Box>
                      )}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onInputChange={(event, any) => handleTo(event, any)}
                      renderInput={(params) =>
                        <TextField
                          className="TextField"  {...params}
                          label="Where to"
                          value={to}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <ToIcon className="Adorn"/>
                              </InputAdornment>
                            )
                          }}
                          sx={{
                            "& .MuiFormLabel-root": { transform: to.length > 0 ? "" : "translate(40px, 17px) scale(1)" },
                            "& .MuiFormLabel-root.Mui-focused": {color: "#f93b57", transform: "translate(14px, -9px) scale(0.75)"},
                          }}
                            />}
                          sx={{ width: "100%" }}
                    />
                    </div>
                    {isSingle
                    ? <SingleDatePicker
                      id="single"
                      minDate={new Date()}
                      className="Single Date"
		      startDatePlaceholder="Departure"
                      onChange={(startDate) => onDateChange(startDate)} />
                    : <RangeDatePicker
                      id="range"
                      minDate={new Date()}
                      className="Range Date"
		      startDatePlaceholder="Departure"
		      endDatePlaceholder="Return"
                      onChange={(startDate, endDate) => onDateChange(startDate, endDate)} />}
              </div>
              <Button className="Button" variant="contained" sx={{
                borderRadius: 10,
                textTransform: 'capitalize',
                backgroundColor: '#f93b57',
                borderColor: '#f93b57',
                bottom: -33,
                display: 'none'
              }}>
                Search
              </Button>
            </Paper>
            </div>
           </div>
           <div className="Panel" id="panel">
            <div id="progress"></div>
            <div id="table"></div>
           </div>

	   <div className="Alert">
	  	<Alert className="Info" severity="warning">Get the travel advise you need here. <a href="#">Read more</a></Alert>
	   </div>

   	   <div className="Promo"><Box className="Swipe">
	  	<Paper
	          square
	          elevation={0}>
	  	  <div className="NewHeader">Need Suggestions?</div>
	          <div className="NewSubHeader">Checkout destinations that may interest you!</div>
	  	</Paper>
	        <AutoPlaySwipeableViews
	          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
	          index={activeStep}
	          onChangeIndex={handleStepChange}
	          enableMouseEvents
	        >
	          {images.map((step, index) => (
			            <div key={step.imgPath}>
			              {Math.abs(activeStep - index) <= 2 ? (
					      		    <div dx={{ position: "relative" }}>
					                    <img
					                      component="img"
					      		      className="SwipeImg"
					                      src={step.imgPath}
					                      alt={step.label}
					                    />
					      		    <div class="SwipeText">{images[activeStep].label}</div>
					      		    </div>
					                  ) : null}
			            </div>
			          ))}
	        </AutoPlaySwipeableViews>
	        <MobileStepper
	          steps={maxSteps}
	          position="static"
	          activeStep={activeStep}
	          nextButton={
			            <Button
			              size="small"
			              onClick={handleNext}
			              disabled={activeStep === maxSteps - 1}
			            ></Button>
			          }
	          backButton={
			            <Button
			              size="small"
			              onClick={handleBack}
			              disabled={activeStep === 0}
			            ></Button>
			          }
	  	  sx={{ marginTop: '-24px' }}
	        />
	      </Box></div>

	        <Dialog
	          open={info}
	          TransitionComponent={Transition}
	          keepMounted
	          onClose={handleCloseInfo}
	          aria-describedby="alert-dialog-slide-description"
	        >
	          <DialogContent>
	            <DialogContentText id="alert-dialog-slide-description">
	  		{message}
	            </DialogContentText>
	          </DialogContent>
	          <DialogActions>
	            <Button onClick={handleCloseInfo}>Ok</Button>
	          </DialogActions>
	        </Dialog>

	  <div className="footer">
	       <BottomNavigation
	          showLabels
	        >
	          <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
	          <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
	          <BottomNavigationAction label="Contact" icon={<HeadsetMicIcon />} />
	        </BottomNavigation>
	  </div>

	  <CookieConsent buttonText="Ok" buttonStyle={{ color: "#fff", backgroundColor: "#f93b57", fontSize: "13px" }}>Allow cookies to enhance experience.</CookieConsent>

        </div>
      </ThemeProvider>
      </React.Fragment>
  );
}

const airports = [
  { label: 'Nairobi', value: 'NBO' },
  { label: 'Mombasa', value: 'MBA' },
  { label: 'Kisumu', value: 'KIS' },
  { label: 'Eldoret', value: 'EDL' },
  { label: 'Malindi', value: 'MYD' },
  { label: 'Wilson, Nairobi', value: 'WIL' },
  { label: 'Diani, Ukunda', value: 'UKA' },
  { label: 'Jo-burg', value: 'JNB' },
  { label: 'Cape Town', value: 'CPT' },
  { label: 'Lagos', value: 'LOS' },
  { label: 'Dar Es Salaam', value: 'DAR' },
  { label: 'Kampala', value: 'KLA' },
  { label: 'Addis Ababa', value: 'ADD' },
  { label: 'Dubai', value: 'DBX' },
  { label: 'Doha', value: 'DOH' },
  { label: 'Abu Dhabi', value: 'AUH' },
  { label: 'Mumbai', value: 'BOM' },
  { label: 'New Delhi', value: 'DEL' },
  { label: 'Ahmedabad', value: 'AMD' },
  { label: 'London', value: 'LHR' },
  { label: 'Paris', value: 'CDG' },
  { label: 'Frankfurt', value: 'FRA' },
  { label: 'Barcelona', value: 'BCN' },
  { label: 'New York', value: 'JFK' },
  { label: 'San Francisco', value: 'SFO' },
  { label: 'Atlanta', value: 'ATL' },
  { label: 'Chicago', value: 'ORD' },
  { label: 'Toronto', value: 'YYZ' },
  { label: 'Amsterdam', value: 'AMS' },
  { label: 'Stockholm', value: 'STO' },
  { label: 'Sydney', value: 'SYD' },
  { label: 'Melbourne', value: 'MEL' },
  { label: 'Athens', value: 'ATH' },
  { label: 'Beijing', value: 'PEK' },
  { label: 'Hon Kong', value: 'HKG' },
  { label: 'Shanghai', value: 'PVG' },
  { label: 'Tokyo', value: 'HND' },
  { label: 'Istanbul', value: 'IST' },
  { label: 'Jakarta', value: 'CGK' },
  { label: 'Kuala Lumpur', value: 'KUL' },
  { label: 'Singapore', value: 'SIN' }
]

const passengers = [
  'Adult',
  'Children',
  'Infants'
];

function optionLabel(option) {
  return option.value;
}

const filterOptions = createFilterOptions({
  stringify: (option) => option.value + option.label,
});

function createData(uuid, id, name, from, to, adults, children, infants, fa, ta, dep, arr, dur, num, cls, typ, currency, price, data, size) {
  return {
    uuid,
    id,
    name,
    from,
    to,
    adults,
    children,
    infants,
    fa,
    ta,
    dep,
    arr,
    dur,
    num,
    cls,
    typ,
    currency,
    price,
    data,
    size
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

   //Temporary solution
  const navigateTo = function() {
    showLoading(true);
    const pricePost = async () => {
      const body = JSON.stringify({
        data: {
          data: row.data,
          size: row.size
        },
        outboundItineraryId: row.id
      });
      console.log(body);
      const response = await fetch('https://betaza.travelstart.com/website-services/api/price?language=en&correlation_id=' + row.uuid, {
        method: 'POST',
        body: body, // string or object
        headers: {
          'content-type': 'application/json',
          'ts-country': 'KE'
        }
      });
      json = await response.json(); //extract JSON from the http response
      console.log(JSON.stringify(json.data));

      var params =
        "uuid=" + row.uuid
        + "&id=" + row.id
        + "&from=" + row.fa
        + "&to=" + row.ta
        + "&adults=" + row.adults
        + "&children=" + row.children
        + "&infants=" + row.infants
        + "&type=" + row.typ
        + "&class=" + row.cls
        + "&duration=" + row.dur
        + "&currency=" + row.currency
        + "&cost=" + row.price
        + "&num=" + row.num;

      sessionStorage.setItem('params', params);
      sessionStorage.setItem('data', JSON.stringify(json.data));

      window.location.href="./book?uuid=" + row.uuid;
      //showLoading(false);
    };
    pricePost();
  };

  return (
    <React.Fragment>
      <TableRow  sx={{ '& > *': { borderBottom: 'unset' }}} className="Row">
        <TableCell onClick={navigateTo} align="left" component="th" scope="row" class="Row Normal">
          <div className="First" sx={{textAlign: 'left', fontFamily: 'Product Sans'}}>{row.dep} - {row.arr}</div>
          <div className="Small">{row.name}</div>
        </TableCell>
        <TableCell onClick={navigateTo} class="Row">
            <div className="Second" sx={{textAlign: 'left', fontFamily: 'Product Sans'}}>{row.dur}</div>
            <div className="Small">{row.from} - {row.to}</div>
        </TableCell>
        <TableCell onClick={navigateTo} class="Row Third" sx={{textAlign: 'center', fontSize: 16, fontFamily: 'Product Sans'}}>{row.num}</TableCell>
        <TableCell onClick={navigateTo} class="Row Last" sx={{textAlign: 'right', fontSize: 16, fontFamily: 'Product Sans'}}>{row.currency + " " + numberWithCommas(row.price)}</TableCell>
          <TableCell align="right">
            <IconButton
              aria-label="expand row"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginBottom: 2 }}>
              <div className="Travel VCenter"><FromIcon className="Adorn"/>{row.fa}</div>
              <div className="TravelLight VCenter"><DotsIcon className="Adorn"/>Travel time {row.dur}</div>
              <div className="Travel VCenter"><ToIcon className="Adorn"/>{row.ta}</div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    dep: PropTypes.string.isRequired,
    dur: PropTypes.string.isRequired,
    arr: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    fa: PropTypes.string.isRequired,
    ta: PropTypes.string.isRequired,
    adults: PropTypes.number.isRequired,
    children: PropTypes.number.isRequired,
    infants: PropTypes.number.isRequired,
    cls: PropTypes.string.isRequired,
    typ: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    num: PropTypes.string.isRequired,
  }).isRequired,
};

 function search(travel_type, class_type, from, to, adults, children, infants, ondate, returndate) {
    showLoading(true);

    var uuid = createUUID();

    var count = parseInt(adults) + parseInt(children) + parseInt(infants);

    console.log("Search criteria - "+uuid+"-"+travel_type+"-"+from+"-"+to+"-"+count+"-"+class_type+"-"+ondate+"-"+returndate+"-");

    const flightsPost = async () => {
      const body = JSON.stringify({
        tripType: travel_type,
        travellers: {
          adults: adults,
          children: children,
          infants: infants
        },
        moreOptions: {
            preferredCabins: {
              value: class_type,
              display: "string"
            },
            isCalendarSearch: true
        },
        locale: {
          country: "KE",
          currentLocale: "en",
          locales: ["en"]
        },
        itineraries: [
          {
              origin: {
                  value: {
                      code: from,
                      type: "airport"
                  }
              },
              destination: {
                  value: {
                      code: to,
                      type: "airport"
                  }
              },
              departDate: ondate,
              returnDate: returndate
          }
        ]
      });
      console.log(body);

      try {
      	const response = await fetch('https://travelstart.com/website-services/api/search?language=en&correlation_id=' + uuid, {
        	method: 'POST',
        	body: body, // string or object
        	headers: {
          	'content-type': 'application/json',
          	'ts-country': 'KE'
        	}
      	});

      	json = await response.json(); //extract JSON from the http response
      	console.log(json);
      	//Express.render(uuid, json, count)
	hideInfo();
      } catch (e) {
        console.error(e.message);
	showLoading(false);
	showInfo("Sorry, something went wrong. " + e.message + ". Please try again later.");
	return;
      }

      var rowFlights = [];
      if(json && "response" in json){
	if(json.response.errors != null && json.response.errors.length > 0) {
		showInfo("Sorry, something went wrong (" + json.response.errors[0].errorWarningAttributeGroup.shortText + ")");
		showLoading(false);
		return;
	}

        var index;
        var fa;
        var ta;
        var airports = json.response.airportInfos;
        if(airports != null && airports.length > 0) for(index in airports){
          var airport = airports[index];
          if(airport.iataCode == from) fa = airport.airport;
            if(airport.iataCode == to) ta = airport.airport;
        }

        if(fa && !fa.toLowerCase().includes("airport"))
          fa = fa + " Airport";
        if(ta && !ta.toLowerCase().includes("airport"))
          ta = ta + " Airport";

        var segment;
        var flight;
        var flights =  "outboundItineraries" in json.response ? json.response.outboundItineraries
            :  "itineraries" in json.response ? json.response.itineraries
            : null;
        if(flights != null && flights.length > 0) for(index in flights){
          flight = flights[index];
          segment = flight.odoList[0].segments[0];
          var id = flight.id;
          var name = json.response.airlineNames[segment.airlineCode];
          var d = new Date(segment.departureDateTime);
          var a = new Date(segment.arrivalDateTime);
          var dep = ((d.getHours() < 10 ? "0" : "") + d.getHours()) + ":" + ((d.getMinutes() < 10 ? "0" : "") + d.getMinutes());
          var arr = ((a.getHours() < 10 ? "0" : "") + a.getHours()) + ":" + ((a.getMinutes() < 10 ? "0" : "") + a.getMinutes());
          var dur = shortEnglishHumanizer(segment.duration);
          var code = flight.currencyCode;
          var amt = flight.amount;
          var num = segment.flightNumber;
          var cls = segment.cabinClass;
          var data = json.response.data.data;
          var size = json.response.data.size;

          rowFlights.push(createData(uuid, id, name, from, to, adults, children, infants, fa, ta, dep, arr, dur, num, cls, travel_type, code, amt, data, size));
        }
      }

      showLoading(false);
      ReactDOM.render(
        <ThemeProvider theme={theme}>
          <TableContainer component={Paper} className="Table">
            <Table aria-label="collapsible table" id="table">
            <TableBody>
              {rowFlights.map((row) => (
                <Row key={row.name + row.id} row={row} />
              ))}
            </TableBody>
            </Table>
          </TableContainer>
          </ThemeProvider>, document.getElementById('table'));

	return "";
    }
    flightsPost();
  }

function createUUID() {
   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
   });
}

function showInfo(msg) {
   info = true;
   message = msg;
};

function hideInfo() {
   info = false;
   message = "";
};

function showLoading(b) {
  loading = b;
  console.log("Loading: " + loading);
  ReactDOM.render(<Box/>, document.getElementById('table'));
  if(loading) ReactDOM.render(<ThemeProvider theme={theme}><Progress className="Progress"  color="primary"/></ThemeProvider>, document.getElementById('progress'));
  else ReactDOM.render(<Box/>, document.getElementById('progress'));
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
