import React, { useRef, useState } from "react";
import ReactDOM from 'react-dom';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Badge from '@mui/material/Badge';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputBase from '@mui/material/InputBase';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Progress from '@mui/material/CircularProgress';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useCookies } from 'react-cookie';

import IconButton from '@mui/material/IconButton';
import {
  SingleDatePicker
} from "react-google-flight-datepicker";

import AdultIcon from '@mui/icons-material/PersonOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EventIcon from '@mui/icons-material/Event';
import NotificationIcon from '@mui/icons-material/NotificationsNone';
import SwapIcon from '@mui/icons-material/SwapHoriz';

import FromIcon from '@mui/icons-material/TripOrigin';
import ToIcon from '@mui/icons-material/GpsFixed';
import DotsIcon from '@mui/icons-material/MoreVert';

import "./css/Book.css";
import "./css/style.css";

  const theme = createTheme({
    palette: {
      primary: {
        light: '#f9eef0',
        main: '#f93b57',
        dark: '#844800',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ccc',
        main: '#666',
        dark: '#222',
        contrastText: '#000',
      },
    },
  });

var response;
var code;
var json;
var loading;
var values = [];

export default function Book(props) {
    const [open, setOpen] = React.useState(false);

    const location = useLocation();

    var params = sessionStorage.getItem('params');
    var query = params.split("&");
    var uuid = query[0].split("=")[1];
    var id = query[1].split("=")[1];
    var from = query[2].split("=")[1].replaceAll("%20", " ");
    var to = query[3].split("=")[1].replaceAll("%20", " ");
    var adults = query[4].split("=")[1].replaceAll("%20", " ");
    var children = query[5].split("=")[1].replaceAll("%20", " ");
    var infants = query[6].split("=")[1].replaceAll("%20", " ");
    var count = parseInt(adults) + parseInt(children) + parseInt(infants);
    var type = query[7].split("=")[1].replaceAll("%20", " ");
    var cls = query[8].split("=")[1].replaceAll("%20", " ");
    var duration = query[9].split("=")[1].replaceAll("%20", " ");
    var currency = query[10].split("=")[1].replaceAll("%20", " ");
    var cost = query[11].split("=")[1].replaceAll("%20", " ");
    var num = query[12].split("=")[1].replaceAll("%20", " ");

    var data = JSON.parse(sessionStorage.getItem('data'));

    var forms=[];
    for(var i=0;i<count;i++) {
        forms.push(<Form key = { i } value={ i } count={ count }/>);
    }

    const theme = createTheme({
      palette: {
        primary: {
          light: '#f9eef0',
          main: '#f93b57',
          dark: '#844800',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ccc',
          main: '#666',
          dark: '#222',
          contrastText: '#000',
        },
      },
    });

    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
      }),
    }));

    return (
      <ThemeProvider theme={theme}>
        <div className="App">

         <AppBar className="AppBar" elevation={0} position="fixed">
           <Toolbar className="Toolbar">
             <IconButton
               aria-label="back"
              edge="start"
               sx={{
                 marginRight: '36px',
                 ...(open && { display: 'none' }),
               }}
             >
               <ArrowBackIcon />
             </IconButton>
             <Typography
               color="#333"
               component="div"
                sx={{
                  ...(open ? { display: 'none' } : {alignSelf: 'flex-end'}),
                }}>
               <h3><strong>Safiri</strong>Express</h3>
             </Typography>
             <div className="Menu">
               <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  color="secondary"
                   sx={{
                     float: 'end',
                   }}
                  >
                  <NotificationIcon />
              </IconButton>
            </div>
           </Toolbar>
         </AppBar>

         <Box className="SectionMargin">
          <Box className="Panel">
            <Box className="Section">
              <div className="Text">{type}</div><div className="Dot">.</div>
              <AdultIcon className="AdornSmall"/><div className="Text">{count}</div><div className="Dot">.</div>
              <div className="Text">{cls}</div>
            </Box>
            <Box className="PanelSmall">
              <div className="Text Large">{from}</div>
              <SwapIcon className="AdornLarge"/>
              <div className="Text Large">{to}</div>
            </Box>
            <Box className="PanelLarge">
              <div className="Text Large"><FromIcon className="Adorn"/>{from}</div>
              <Box className="TravelLight VCenter" sx={{paddingTop: "4px", paddingBottom: "4px"}}><DotsIcon className="AdornLarge"/>Travel time {duration}</Box>
              <div className="Text Large"><ToIcon className="Adorn"/>{to}</div>
            </Box>
            </Box>
          <Box className="Form">
            {forms}
            { !json ? <Box id="button"><Button className="Button" variant="contained" onClick={() => handleBook(i, uuid, id, num, currency, cost, data)}>Book</Button></Box>
              : json.code == 200 ? <Alert severity="info">Success</Alert>
              : <Alert severity="error">json.result</Alert> }
           </Box>
         </Box>
        </div>
      </ThemeProvider>
    );
}

function Form(props) {
  const map = new Map();
  map.set('title', "");
  map.set('first', "");
  map.set('last', "");

  var i = props.value + 1;
  var count = props.count;
  const [first, setFirst] = React.useState("");
  const [middle, setMiddle] = React.useState("");
  const [last, setLast] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [number, setNumber] = React.useState("");

  const [cookies, setCookie] = useCookies(['name']);

  var title = "Mr";
  var type = "Adult";
  var country = "KE";
  var email = "mbugua.njihia@gmail.com";
  var doc;
  var exp

  const handleTitle = (event, value) => {
    title = value;
    populate();
  }

  const handleFirst = (event) => {
    setFirst(event.target.value);
    setCookie('name', first, { path: '/' });
    populate();
  }

  const handleMiddle = (event) => {
    setMiddle(event.target.value);
    populate();
  }

  const handleLast = (event) => {
    setLast(event.target.value);
    populate();
  }

  const handleEmail = (event) => {
    email = event.target.value;
    populate();
  }

  const handlePhone = (event) => {
    setPhone(event.target.value);
    populate();
  }

  const handleDob = (event) => {
    setDob(event.target.value);
    populate();
  }

  const handleCountry = (event, value) => {
    country = value;
    populate();
  }

  const handleDocument = (event, value) => {
    doc  = value;
    populate();
  }

  const handleNumber = (event) => {
    setNumber(event.target.value);
    populate();
  }

  const handleType = (event, value) => {
    type = value;
    populate();
  }

  const onDobChange = (dob) => {
    //if(start != null)
    //  dob = start.getFullYear() + "-" + ((String(start.getMonth()+1).length==1 ? "0" : "") + (start.getMonth()+1)) + "-" + ((String(start.getDate()).length==1 ? "0" : "") + start.getDate());
    populate();
  }

  const onExpChange = (start) => {
    if(start != null)
      exp = start.getFullYear() + "-" + ((String(start.getMonth()+1).length==1 ? "0" : "") + (start.getMonth()+1)) + "-" + ((String(start.getDate()).length==1 ? "0" : "") + start.getDate());
    populate();
  }

  const populate = function() {
    if(title) map["title"] = title;
    if(first) map["first"] = first;
    if(middle) map["middle"] = middle;
    if(last) map["last"] = last;
    if(email) map["email"] = email;
    if(phone) map["phone"] = phone;
    if(country) map["country"] = country;
    if(doc) map["document"] = doc;
    if(number) map["number"] = number;
    if(type) map["type"] = type;
    if(dob) map["dob"] = dob; else map["dob"] = "";
    if(exp) map["expiry"] = exp; else map["expiry"] = "";
    values[i-1] = map;
    //console.log(JSON.stringify(values));
  }

  const SelectInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
    },
    '& .MuiInputBase-input': {
      display: 'flex',
      border: '0px',
      color: '#555',
      fontSize: 13.5,
      fontFamily: [
        'Product Sans',
        'Roboto',
      ].join(','),
    },
  }));

  return(
      <Box> <Box id="form" sx={{ display: 'block', marginTop: '0px !important', marginBottom: '20px !important' }}>
      <div className="Text Title">Passenger { count <= 1 ? "" : i }</div>
      <div>
        <Autocomplete
          label="Title"
          className="Select"
          options={titles}
          className="Field"
          sx={{ display: 'inline-flex', m: 1 }}
          onInputChange={(event, any) => handleTitle(event, any)}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => <TextField {...params} label="Title" />} />
        <TextField
          label="First Name"
          id="outlined-start-adornment"
          onChange={handleFirst}
          className="Field"
          required
          sx={{ m: 1 }}
        />
        </div>
        <div>
          <TextField
            label="Middle Name"
            id="outlined-start-adornment"
            onChange={handleMiddle}
            className="Field"
            sx={{ m: 1 }}
          />
          <TextField
            label="Last Name (Surname)"
            id="outlined-start-adornment"
            onChange={handleLast}
            className="Field"
            required
            sx={{ m: 1 }}
          />
        </div>
        <div>
          <TextField
            label="Email"
            id="outlined-start-adornment"
            onChange={handleEmail}
            className="Field"
            required
            sx={{ m: 1 }}
          />
            <TextField
              label="Phone Number"
              id="outlined-start-adornment"
              onChange={handlePhone}
              className="Field"
              required
              sx={{ m: 1 }}
            />
          </div>
          <div className="Center">
            <TextField
              id="date"
              label="Date of Birth"
              type="date"
              className="Field"
              onChange={handleDob}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ display: 'inline-flex', m: 1}}
            />
            <Autocomplete
                id="country-select"
                className="Field"
                sx={{ display: 'inline-flex', m: 1}}
                options={countries}
                autoHighlight
                onInputChange={(event, any) => handleCountry(event, any)}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>
            <div >
              <Autocomplete
                label="Document Type"
                className="Select"
                options={types}
                className="Field"
                sx={{ display: 'inline-flex', m: 1 }}
                onInputChange={(event, any) => handleDocument(event, any)}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Document Type" />} />
                <TextField
                  label="Document Number"
                  id="outlined-start-adornment"
                  className="Field"
                  required
                  onChange={handleNumber}
                  sx={{ display: 'inline-flex', m: 1, width: '25ch' }}
                />
              </div>
              <div className="Center">
                <SingleDatePicker
                  id="exd"
                  startDatePlaceholder="Document Expiry"
                  minDate={new Date()}
                  onChange={(exp) => onExpChange(exp)}
                  sx={{ display: 'inline-flex', m: 1, width: '25ch' }} />
                <Autocomplete
                  label="Type"
                  className="Select"
                  options={passengers}
                  className="Field"
                  onInputChange={(event, any) => handleType(event, any)}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  sx={{ display: 'inline-flex', m: 1 }}
                  renderInput={(params) => <TextField {...params} label="Type" />} />
                </div>
      </Box> <div id="progress"></div> </Box>
  );
}

const handleBook = function(i, uuid, id, num, currency, cost, data) {
      var index;
      var adults = [];
      var children = [];
      var infants = [];
      for(index in values) {
        var value = values[index];
        console.log(JSON.stringify(value));
        var passenger = {
              id: i,
              type: !value["type"] ? "ADULT" : value["type"].toUpperCase(),
              email: value["email"],
              title: value["title"],
              firstName: value["first"],
              middleName: value["middle"],
              lastName: value["last"],
              documentType: !value["document"] ? "PASSPORT" : value["document"].toUpperCase(),
              documentNumber: value["number"],
              passportNumber: value["number"],
              passportCountry: value["country"],
              passportExpiry: {
                day: value["expiry"].split("-")[2],
                month: value["expiry"].split("-")[1],
                year: value["expiry"].split("-")[0]
              },
              nationality: value["country"],
              baggageSelection: [
                {
                  id: 0,
                  selected: false
                }
              ],
              seatDetails: [
                {
                  flightNumber: id,
                }
              ],
              dob: {
                day: value["dob"].split("-")[2],
                month: value["dob"].split("-")[1],
                year: value["dob"].split("-")[0]
              },
            };

        if(value["type"] == "Adult" || value["type"] == "ADULT")
          adults.push(passenger);
        if(value["type"] == "Child" || value["type"] == "CHILD")
          children.push(passenger);
        if(value["type"] == "Infant" || value["type"] == "INFANT")
          infants.push(passenger);
      }

      const body = JSON.stringify({
        passengers: {
          adults,
          children,
          infants,
        },
        data: data,
        products: [
          {
            id: "WHATSAPP",
            selectedValue: true
          },
          {
            id: "SMS",
            selectedValue: true
          },
        ],
        billingDetails: {
          addressLine1: "Nairobi",
          postalCode: "00100",
          city: "Nairobi",
          country: "KE",
          contactCode: "254",
          contactNo: "0721668709",
          vatInvoice: false,
          companyName: "SafiriExpress",
        },
        paymentReturnURL: "https://api.tupay.app/v1/payments/callback/result",
        contact: {
          title: values.length == 0 ? "Mr" : values[0]["title"],
          firstName: values.length == 0 ? "" : values[0]["first"],
          lastName: values.length == 0 ? "" : values[0]["last"],
          email: values.length == 0 ? "" : values[0]["email"],
          mobileCode: "254",
          mobileNo: values.length == 0 ? "" : values[0]["phone"].startsWith("+254") ? values[0]["phone"].substring(4) : values[0]["phone"].startsWith("254") ? values[0]["phone"].substring(3) : values[0]["phone"]
        },
        ignoreDuplicateBookings: false
      }
    );
  console.log(body);

    const paymentPost = async () => {
      showLoading(true);
      response = await fetch('https://travelstart.com/website-services/api/payment-methods?language=en&correlation_id=' + uuid, {
        method: 'POST',
        body: body, // string or object
        headers: {
          'content-type': 'application/json',
          'ts-country': 'KE'
        }
      });
      json = await response.json();
      console.log(json);

      if(json.data) {
        const bookPost = async () => {
          showLoading(true);

          var paymentMethod;
          for(var i in json.paymentOptions) {
            var paymentOption = json.paymentOptions[i];
            if("MPESA" === paymentOption.paymentOptionName) {
              paymentMethod = paymentOption.paymentMethods[0];
              console.log(paymentOption.paymentOptionName + " : " + JSON.stringify(paymentMethod));
              break;
            }
          }

          var j = JSON.parse(body);
          j.paymentData = JSON.parse('{"data": "' + json.data.data + '","size": "' + json.data.size + '"}');
          j.selectedPaymentMethod = paymentMethod;
          console.log(JSON.stringify(j));

          response = await fetch('https://travelstart.com/website-services/api/book?language=en&correlation_id=' + uuid, {
            method: 'POST',
            body: JSON.stringify(j), // string or object
            headers: {
              'content-type': 'application/json',
              'ts-country': 'KE'
            }
          });
          json = await response.json();
          console.log(JSON.stringify(json));
          var info = '<h3 class="App">Successfully booked flight ' + num + '</h3>'
            + 'Booking Refrence: ' + json.bookingInformation.bookingReferenceNo + '<br/>'
            + 'Pay Before: ' + json.bookingInformation.lastPayDate.replace("T", " ");
          showResponse(info, false);
        };
        bookPost();
      } else if(json.result)
        showResponse(json.result, false);
      else
        showResponse("No response found. Please try again later.", false);
    };
    //paymentPost();
    showResponse('<p class="SubTitle">Successfully booked flight ' + num + '</p>'
      + '<p class="SubTitle">Booking Refrence: ' + '12345' + '</p>'
      + '<p class="SubTitle">Pay Before: ' + '2022-01-01T11:00:00'.replace("T", " ") + '</p>');
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries = [
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'AD', label: 'Andorra', phone: '376' },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
  },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
  },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
  },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
  },
  {
    code: 'CD',
    label: 'Congo, Democratic Republic of the',
    phone: '243',
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
  },
  {
    code: 'CG',
    label: 'Congo, Republic of the',
    phone: '242',
  },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
  },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
  },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
  },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
  },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
  },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
  },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850',
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
  },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
  },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
  },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
  },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
  },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
  },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
  },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
  },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
  },
  { code: 'TD', label: 'Chad', phone: '235' },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
  },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
  },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    phone: '886',
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
  },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
  },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
  },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

const titles = [
  { label: 'Mr', value: "Mr" },
  { label: 'Mrs', value: "Mrs" },
  { label: 'Miss', value: "Miss" },
  { label: 'Ms', value: "Ms" },
];

const passengers = [
  { label: 'Adult', value: "ADULT" },
  { label: 'Child', value: "CHILD" },
  { label: 'Infant', value: "INFANT" }
];

const types = [
  { label: 'ID', value: "ID" },
  { label: 'Passport', value: "PASSPORT" },
];


function showLoading(b) {
  showResponse("", b)
}

function showResponse(response, b) {
  loading = b;
  console.log("Loading: " + loading + ", Response: " + response);
  ReactDOM.render(<Box/>, document.getElementById('form'));
  ReactDOM.render(<Box/>, document.getElementById('button'));
  if(loading) ReactDOM.render(<ThemeProvider theme={theme}><Progress className="Progress"  color="primary"/></ThemeProvider>, document.getElementById('progress'));
  else ReactDOM.render(<div className='App' dangerouslySetInnerHTML={{ __html: response.replace(/\n/g, '<br />')}} />, document.getElementById('progress'));
}
