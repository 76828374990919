import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './css/App.css';

import Search from './Search';
import Book from './Book';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Search/>} />
          <Route path="/book" element={<Book/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
